<template>
    <div class="container">
        <section class="pricing-panel">
            <div class="header">
                <h1>{{ $t("Streamline your Recruitment. Start free.") }}</h1>
                <h4>{{ $t("Choose the perfect plan for your business needs") }}</h4>
            </div>
            <div class="saving-container">
                <div class="save-span">
                    <span>{{ $t("Save 15%") }}</span> {{ $t("on monthly plan!") }}
                </div>
                <div class="time-container">
                    <button class="monthly">{{ $t("Monthly") }}</button>
                    <button class="yearly">{{ $t("Yearly") }}</button>
                </div>
            </div>
            <div class="pricing-cards-wrapper">
                <div class="pricing-card"></div>
                <div class="pricing-card"></div>
                <div class="pricing-card"></div>
                <div class="pricing-card"></div>
            </div>
        </section>
        <section class="pricing-table"></section>
    </div>
</template>

<script>
export default {
    name: "PricingPage",
    data() {
        return {};
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.pricing-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        color: var(--sementics-color-fg-default, #18181b);
        /* sementic.type/title/xl */
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 115%;
        /* 36.8px */
    }

    h4 {
        color: var(--sementics-color-fg-default, #18181b);
        /* sementic.type/body/xl */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        /* 22.5px */
    }
}

.saving-container {
    display: flex;
    align-items: center;
}

.time-container {
    display: inline-flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 100px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-primary-on-default, #fff);
    margin-left: 10px;
}

.monthly {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #2196f3;
    color: var(--sementics-color-primary-on-default, #fff);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    /* 16.1px */
}

.yearly {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    color: var(--sementics-color-fg-disabled, #a1a1aa);
    background: #fff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    /* 16.1px */
}

.save-span {
    color: #2196f3;
    /* sementic.type/label/s */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.save-span span {
    color: #2196f3;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 15px */
}

.pricing-cards-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
