<template>
    <main class=" ">
        <HeroSection />
        <CreateSection />
        <InviteSection />
        <AnalyzeSection />
        <ValueSection />
        <!--  <TestimonySection />-->
        <div class="flex flex-col justify-between items-center w-4/5 border border-slate-300 rounded-lg py-8 mx-auto mt-20">
            <span class="font-light text-base text-center w-fit px-5 py-3 mb-4 text-NeonBlue">Start Free</span>
            <h1 class="font-bold text-3xl text-slate-700 text-center w-full mt-6 mb-2">Re-invent your hiring process now.</h1>
            <p class="font-light text-base w-full mb-12">Start using GO PLATFORM today, or talk to us about your hiring challenges to discover how we can help.</p>
            <div class="flex flex-col lg:flex-row justify-center items-center gap-8 w-full">
                <button class="w-3/5 lg:w-40 h-14 rounded-md text-base font-bold px-4 py-2 border border-slate-500 text-slate-700">Free Demo</button>
                <button class="w-3/5 lg:w-40 h-14 rounded-md text-base font-bold px-4 py-2 text-white border bg-NeonBlue border-NeonBlue">Get Started</button>
            </div>
        </div>
    </main>
</template>

<script>
import HeroSection from "@/components/productTour/HeroSection.vue";
import CreateSection from "@/components/productTour/CreateSection.vue";
import AnalyzeSection from "@/components/productTour/AnalyzeSection.vue";
import InviteSection from "@/components/productTour/InviteSection.vue";
import ValueSection from "@/components/productTour/ValueSection.vue";
//import TestimonySection from "@/components/productTour/TestimonySection.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);
export default {
    name: "ProductTour",
    data() {
        return {
            resultStep: 1,
            projectStep: 1,
        };
    },
    components: {
        HeroSection,
        CreateSection,
        AnalyzeSection,
        InviteSection,
        ValueSection,
        // TestimonySection
    },
    mounted() {
        // const sectionsArray = gsap.utils.toArray(".sections");
        // sectionsArray.forEach((section, i) => {
        //     const pinned = i < sectionsArray.length - 1 ? true : false;
        //     ScrollTrigger.create({
        //         trigger: section,
        //         start: "top top",
        //         end: pinned ? "bottom top" : "bottom bottom",
        //         pin: true,
        //         pinSpacing: false,
        //         snap: 1,
        //     });
        // });
        const createProject = gsap.timeline({
            repeat: -1,
        });
        createProject.to(".handPointer", {
            x: 200,
            y: -70,
            duration: 3,
            ease: "ease",
        });
        createProject.to(".createBtn", {
            scale: 0.95,
            duration: 0.5,
            ease: "ease",
            onComplete: () => {
                // if(this.projectStep< 4){this.projectStep++}
            },
        });
        const projectForm = gsap.timeline({
            repeat: -1,
        });
        projectForm.to(".handPointer2", {
            x: -240,
            y: 340,
            duration: 3,
            ease: "ease",
        });
        projectForm.to(".createBtn2", {
            scale: 0.95,
            duration: 0.5,
            ease: "ease",
            onComplete: () => {
                // if(this.projectStep< 4){this.projectStep++}
            },
        });
        const selectAssess = gsap.timeline({
            repeat: -1,
        });
        selectAssess.to(".handPointer3", {
            x: 150,
            y: -100,
            duration: 3,
            ease: "ease",
        });
        selectAssess.to(".assessCard2", {
            scale: 0.95,
            duration: 0.5,
            ease: "ease",
        });
        selectAssess.from(".newAssess", {
            scale: 0,
            duration: 2.5,
            ease: "ease",
            onComplete: () => {
                // if(this.projectStep< 4){this.projectStep++}
            },
        });

        const invite = gsap.timeline({
            repeat: -1,
        });
        invite.to(".handPointer4", {
            x: 230,
            y: -140,
            duration: 3,
            ease: "ease",
        });
        const talentMap = gsap.timeline({
            repeat: -1,
        });
        talentMap.to(".hand_Pointer", {
            motionPath: {
                path: [
                    { x: 0, y: 0 },
                    { x: -140, y: 0 },
                    { x: 0, y: 180 },
                ],
            },
            duration: 10,
            ease: "ease",
        });
        gsap.from(".full_report", {
            scale: 0,
            duration: 6,
            stagger: 2,
            ease: "ease",
            repeat: -1,
        });
        gsap.from(".result_widget", {
            scale: 0,
            duration: 6,
            stagger: 2,
            ease: "ease",
            repeat: -1,
        });
    },
};
</script>

<style lang="scss" scoped></style>
