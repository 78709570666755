<template>
    <div>
        <div class="txt" style="margin-bottom: 36px; background-color: #fff">
            <h1 class="text">Privacy Policy</h1>
        </div>
        <div class="txt txt-2">
            <h1 class="title">DISCLAIMER</h1>
            <p>Last updated January 1st, 2024</p>

            <h1 class="title">WEBSITE DISCLAIMER</h1>
            <p>
                The information provided by GO PLATFORM (“we,” “us”, or “our”) on go-platform.com (the “Site”) and our mobile application is for general informational purposes only. All information on
                the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy,
                validity, reliability, availability or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR
                DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE
                SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
            </p>

            <h1 class="title">EXTERNAL LINKS DISCLAIMER</h1>
            <p>
                The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating from
                third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity,
                reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
                THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
            </p>

            <h1 class="title">PROFESSIONAL DISCLAIMER</h1>
            <p>
                The Site cannot and does not contain hiring and staffing advice. The hiring and staffing information is provided for general informational and educational purposes only and is not a
                substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not
                provide any kind of hiring and staffing advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE OR OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
            </p>

            <h1 class="title">AFFILIATES DISCLAIMER</h1>
            <p>
                The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such
                links.
            </p>

            <h1 class="title">PRIVACY NOTICE</h1>
            <p>Last updated January 1st, 2024</p>
            <p>
                This privacy notice for our users ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services
                ("Services"), such as when you: Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and
                practices, please do not use our Services.
            </p>

            <h1 class="title">SUMMARY OF KEY POINTS</h1>
            <p>
                This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our
                table of contents below to find the section you are looking for. What personal information do we process? When you visit, use, or navigate our Services, we may process personal
                information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to
                us. Do we process any sensitive personal information? We do not process sensitive personal information. Do we receive any information from third parties? We may receive information
                from public databases, marketing partners, social media platforms, and other outside sources. Learn more about information collected from other sources. How do we process your
                information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also
                process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your
                information. In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more
                about when and with whom we share your personal information. What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information. Learn more about your privacy rights. How do you exercise your rights? The easiest way to exercise your rights is by submitting a
                data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws. Want to learn more about what we do with
                any information we collect? Review the privacy notice in full.
            </p>

            <h1 class="title">1. WHAT INFORMATION DO WE COLLECT?</h1>
            <p class="sub-main">Personal information you disclose to us</p>
            <p><span>In Short:</span>We collect personal information that you provide to us</p>
            <p>
                We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and
                Services, when you participate in activities on the Services, or otherwise when you contact us.
            </p>
            <p><span>Sensitive Information.</span>We do not process sensitive information.</p>
            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
            <p class="sub-main">Information automatically collected</p>
            <p>
                <span>In Short:</span>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
            </p>
            <p>
                We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact
                information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device
                name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and
                operation of our Services, and for our internal analytics and reporting purposes.
            </p>
            <p>Like many businesses, we also collect information through cookies and similar technologies.</p>

            <h1 class="title">2. HOW DO WE PROCESS YOUR INFORMATION?</h1>
            <p>
                <span>In Short:</span>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We
                may also process your information for other purposes with your consent.
            </p>
            <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>

            <h1 class="title">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h1>
            <p><span>In Short:</span>We may share information in specific situations described in this section and/or with the following third parties.</p>
            <p>We may need to share your personal information in the following situations:</p>
            <p>
                <span>Business Transfers.</span>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition
                of all or a portion of our business to another company.
            </p>
            <p>
                <span>Affiliates.</span>We will not share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our
                parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
            </p>
            <p><span>Business Partners.</span>We will not share your information with our business partners to offer you certain products, services, or promotions.</p>

            <h1 class="title">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>
            <p><span>In Short:</span>We may use cookies and other tracking technologies to collect and store your information.</p>
            <p>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you
                can refuse certain cookies is set out in our Cookie Notice.
            </p>

            <h1 class="title">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h1>
            <p><span>In Short:</span>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you</p>
            <p>
                Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we
                will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but
                will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
            </p>
            <p>
                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note
                that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy
                notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
            </p>

            <h1 class="title">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h1>
            <p><span>In Short:</span>We may transfer, store, and process your information in countries other than your own.</p>
            <p>
                Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities
                and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other countries.
            </p>
            <p>
                If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws
                as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
            </p>

            <h1 class="title">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h1>
            <p><span>In Short: </span>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
            <p>
                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by
                law (such as tax, accounting, or other legal requirements).
            </p>
            <p>
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example,
                because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion
                is possible.
            </p>

            <h1 class="title">8. DO WE COLLECT INFORMATION FROM MINORS?</h1>
            <P><span>In Short: </span>We do not knowingly collect data from or market to children under 18 years of age</P>
            <p>
                We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian
                of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will
                deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 18,
                please contact us at contact@go-platform.dz
            </p>

            <h1 class="title">9. WHAT ARE YOUR PRIVACY RIGHTS?</h1>
            <p><span>In Short:</span>You may review, change, or terminate your account at any time.</p>
            <p>
                <span>Withdrawing your consent:</span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable
                law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN
                YOU CONTACT US ABOUT THIS NOTICE?" below.
            </p>
            <p>
                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds other than consent.
            </p>

            <h1 class="title">Account Information</h1>
            <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can: Upon your request to terminate your account, we will deactivate or
                delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </p>

            <h1 class="title">10. CONTROLS FOR DO-NOT-TRACK FEATURE</h1>
            <p>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to
                have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized.
                As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
                tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </p>

            <h1 class="title">11. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
            <p><span>In Short:</span>Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p>
                We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is
                accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We
                encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </p>

            <h1 class="title">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
            <p>If you have questions or comments about this notice, you may contact us by email at: contact@go-platform.dz</p>

            <h1 class="title">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h1>
            <p>
                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To
                request to review, update, or delete your personal information, please fill out and submit a data subject access request.
            </p>

            <h1 class="title">COOKIE POLICY</h1>
            <p>
                This Cookie Policy explains how GO PLATFORM ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at
                <a href="https://go-platform.com">https://go-platform.com ("Website").</a> It explains what these technologies are and why we use them, as well as your rights to control our use of
                them.
            </p>
            <p>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>

            <h1 class="title">What are cookies?</h1>

            <p>
                Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work,
                or to work more efficiently, as well as to provide reporting information.
            </p>
            <p>
                Cookies set by the website owner (in this case, GO PLATFORM) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies."
                Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set
                these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
            </p>

            <h1 class="title">Why do we use cookies?</h1>

            <p>
                We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or
                "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve
                cookies through our website for advertising, analytics, and other purposes. This is described in more detail below.
            </p>
            <h1 class="title">How can I control cookies?</h1>
            <p>
                You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent
                Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
            </p>
            <p>
                The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some
                functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.
            </p>
            <p>
                The specific types of first- and third-party cookies served through our website and the purposes they perform are described in the table below (please note that the specific cookies
                served may vary depending on the specific Online Properties you visit):
            </p>

            <h1 class="title">How can I control cookies on my browser?</h1>
            <p>
                As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser's help menu for more information. The
                following is information about how to manage cookies on the most popular browsers:
            </p>
            <p><span>Chrome</span></p>
            <p><span>Internet Explorer</span></p>
            <p><span>Firefox</span></p>
            <p><span>Safari</span></p>
            <p><span>Edge</span></p>
            <p><span>Opera</span></p>
            <p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit:</p>
            <p><span>Digital Advertising Alliance</span></p>
            <p><span>Digital Advertising Alliance of Canada</span></p>
            <p><span>European Interactive Digital Advertising Alliance</span></p>

            <h1 class="title">What about other tracking technologies, like web beacons?</h1>
            <p>
                Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels"
                or "clear gifs"). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our website or opened an email including them.
                This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have
                come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many
                instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.
            </p>

            <h1 class="title">Do you use Flash cookies or Local Shared Objects?</h1>
            <p>
                Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other things, collect and store information about your use of our services,
                fraud prevention, and for other site operations.
            </p>
            <p>
                If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the Website
                Storage Settings Panel. You can also control Flash Cookies by going to the Global Storage Settings Panel and following the instructions (which may include instructions that explain,
                for example, how to delete existing Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being
                asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).
            </p>
            <p>
                Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially,
                Flash applications used in connection with our services or online content.
            </p>

            <h1 class="title">Do you serve targeted advertising?</h1>
            <p>
                Third parties may serve cookies on your computer or mobile device to serve advertising through our website. These companies may use information about your visits to this and other
                websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness
                of advertisements. They can accomplish this by using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements
                about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details, or other
                details that directly identify you unless you choose to provide these.
            </p>
            <h1 class="title">How often will you update this, Cookie Policy?</h1>
            <p>
                We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please
                therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
            </p>
            <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

            <h1 class="title">Where can I get further information?</h1>
            <p>If you have any questions about our use of cookies or other technologies, please contact us at:</p>
            <p>GO PLATFORM</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.text {
    margin-top: 136px;
    color: #292d2a;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1em;
    text-align: left;
}

.txt {
    padding: 50px 256px;
}
.txt-2 {
    text-align: left;
    background-color: #f8f8fa;
    padding-top: 77px;
    font-size: 21px;
    line-height: 34px;
    font-weight: bold;
}

.title {
    color: #292a2d;
    font-weight: bold;
    font-size: 30px;
}

p {
    font-size: 21px;
    line-height: 34px;
    margin: 30px 0px;
    font-weight: 100;
}
.sub-main {
    color: #292a2d;
    font-weight: bold;
    font-size: 25px;
}

span {
    font-size: 21px;
    font-weight: bold;
}
</style>
